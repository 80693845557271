import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';
import { getShopPayText } from '../../Client/utils/shopifyPay.js';

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
};

/* eslint-disable camelcase */
const shopPayTerms = {
  min_price: '$50.00',
  max_price: '$30,000.00',
  financing_plans: [
    {
      min_price: '$50.00',
      max_price: '$149.99',
      terms: [{ apr: 0, loan_type: 'split_pay', installments_count: 4 }],
    },
    {
      min_price: '$150.00',
      max_price: '$999.99',
      terms: [
        { apr: 0, loan_type: 'split_pay', installments_count: 4 },
        { apr: 15, loan_type: 'interest', installments_count: 3 },
        { apr: 15, loan_type: 'interest', installments_count: 6 },
        { apr: 15, loan_type: 'interest', installments_count: 12 },
      ],
    },
    {
      min_price: '$1,000.00',
      max_price: '$30,000.00',
      terms: [
        { apr: 15, loan_type: 'interest', installments_count: 3 },
        { apr: 15, loan_type: 'interest', installments_count: 6 },
        { apr: 15, loan_type: 'interest', installments_count: 12 },
      ],
    },
  ],
  installments_buyer_prequalification_enabled: true,
  seller_id: 1808887,
};
/* eslint-enable camelcase */
window.Convermax.getShopPayText = (item) => getShopPayText(shopPayTerms, item);
